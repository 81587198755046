/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import {
  ClassNameOverrides,
  CleanedRTF,
} from "@bond-london/graphcms-rich-text";

export interface paragraphINFO {
  id: string;
  title?: string;
  headline?: string;
  messagez?: string;
  messagez1?: string;
  messagez2?: string;
  cleanedMessage?: CleanedRTF;
  className?: string;
  rtfClassNameOverrides?: ClassNameOverrides;
  externalVideoUrl?: string;
  externalVideoTitle?: string;
  rtfClassName?: string;
  notes?: object;
  Infographic1?: object;
  infographicTitle1?: string;
  Infographic2?: object;
  infographicTitle2?: string;
  pagraphdivclass?: string;
  noncategeoryContent?: string;
  noncategeoryContent1?: string;
  noncategeoryContent2?: string;
  btnName?: string;
  btnLink?: string;
  btnclassName?: string;
  CarbonInfographImage?: string;
  CarbonInfographImage1?: string;
  DeCarbonInfographImage?: string;
  EnergyImage?: string;
  learnmore?: string;
  messageclass?: string;
  componetcolor?: string;
  sessiondesign?: string;
}

export const GA_paragraph: React.FC<PropsWithChildren<paragraphINFO>> = ({
  title,
  headline,
  messagez,
  messagez1,
  messagez2,
  className,
  notes,
  Infographic1,
  Infographic2,
  infographicTitle1,
  infographicTitle2,
  pagraphdivclass,
  noncategeoryContent,
  noncategeoryContent1,
  noncategeoryContent2,
  btnName,
  btnLink,
  btnclassName,
  CarbonInfographImage,
  CarbonInfographImage1,
  learnmore,
  messageclass,
  componetcolor,
  id,
  sessiondesign,
}) => {
  const redirectlinks = (link: string) => {
    // This will navigate to second component
    window.location.href = link;
  };
  // let realRTF;
  // if (cleanedMessage) {
  //   // realRTF = cleanedMessage;
  // }
  // else if (message) {
  //   realRTF = getCleanedRTF(message);
  // }

  // if (!title && !headline && (!message || !cleanedMessage)) {
  //   return null;
  // }

  let leftClassName: string;
  let rightClassName: string;

  if (!title && !headline) {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
  } else if (headline) {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-span-2 mb-5";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  } else {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-span-2 mb-5";
    // rightClassName =
    //   "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  }

  if (componetcolor == "Grey") {
    return (
      <section
        data-component="ga_paragraph"
        className={classNames("ga-ptb-40 page-grid max-width z-0 ")}
      >
        <div className={classNames("col-span-12 col-start-2", pagraphdivclass)}>
          <h2
            className={classNames(
              leftClassName,
              "ga-font-style-s24",
              className
            )}
          >
            {title}
          </h2>
          <p
            className={classNames(
              "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-span-2 mt-20px lg:mt-0  p3 ga-paragraph-content ga-font-style-s16 ga-line-height-22",
              messageclass
            )}
          >
            {messagez}
            {notes &&
              notes.map(
                (
                  n: {
                    value:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined
                ) => (
                  <>
                    <br />
                    <p
                      className={classNames(
                        "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-span-2 mt-20px lg:mt-0 text-right-margin p3 ga-paragraph-content"
                      )}
                    >
                      {index + 1}.{n.value}
                    </p>
                  </>
                )
              )}
          </p>
        </div>
        {infographicTitle1 && (
          <div className="col-span-12 col-start-2 ">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s16 ga-font-weight-700 justify-center mb-40px"
              )}
            >
              {infographicTitle1}
            </h3>
          </div>
        )}
        {Infographic1 && (
          <div className="col-span-12 col-start-2 ga-infographic-div">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              {infographicTitle1}
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-2 w-full">
              {Infographic1.map(
                (n: {
                  value:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        | string
                        | React.JSXElementConstructor</* eslint-disable @typescript-eslint/no-unsafe-call */ any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <div className="col-span-1 ga-infographic-border">
                    {n.value}
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {btnName && (
          <div className="col-span-12 col-start-2">
            <br></br>
            <center>
              <button
                href={btnLink}
                onClick={() => redirectlinks(btnLink)}
                className={classNames(
                  "col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center",
                  btnclassName
                )}
              >
                <a>
                  <p className="self-center">{btnName}</p>
                </a>
              </button>
            </center>
          </div>
        )}
        {noncategeoryContent && (
          <div className="col-span-12 col-start-2 ga-padding-15 ">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              {noncategeoryContent}
            </h3>
          </div>
        )}
        {CarbonInfographImage && (
          <div className="col-span-12 col-start-2 ga-infographic-div ">
            <div className="grid grid-cols-1 md:grid-cols-1 w-full">
              <div id={"div" + id}>
                <img
                  src={CarbonInfographImage}
                  className="ga-image-infogrph w-full  ga-inforgraphic-social-1"
                  id={id}
                />
              </div>
            </div>
          </div>
        )}
        {Infographic2 && (
          <div className="col-span-12 col-start-2 ga-infographic1-div">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              {infographicTitle2}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-4 w-full mt-50px">
              {Infographic2.map(
                (n: {
                  title:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        | string
                        | React.JSXElementConstructor</* eslint-disable @typescript-eslint/no-unsafe-call */ any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                  value:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        | string
                        | React.JSXElementConstructor</* eslint-disable @typescript-eslint/no-unsafe-call */ any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <div className="col-span-1 ga-infographic1-border">
                    <div className="col-span-12 bg-purple text-white-1 ga-infogrph-head">
                      {n.title}
                    </div>
                    <div className="col-span-12 ga-padding-10 ga-background-lightgrey h-full">
                      {n.value}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {learnmore && (
          <div className="col-span-12 text-center col-start-2">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              <a className="ga-paragraph-title" href="#">
                Learn more
              </a>{" "}
              about our client work in Energy Transition
            </h3>
          </div>
        )}
      </section>
    );
  } else {
    return (
      <section
        data-component="ga_paragraph"
        className={classNames(
          "ga-ptb-40 page-grid max-width z-0 bg-white-1",
          sessiondesign ? sessiondesign : ""
        )}
      >
        <div className={classNames("col-span-12 col-start-2", pagraphdivclass)}>
          <h2
            className={classNames(
              leftClassName,
              "ga-font-style-s24",
              className
            )}
          >
            {title}
          </h2>
          <p
            className={classNames(
              "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-span-2 mt-20px lg:mt-0  p3 ga-paragraph-content ga-font-style-s16 ga-line-height-24",
              messageclass
            )}
          >
            {messagez}
            <sub>{messagez1}</sub>
            {messagez2}
            {notes &&
              notes.map(
                (
                  n: {
                    value:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined
                ) => (
                  <>
                    <br />
                    <p
                      className={classNames(
                        "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-span-2 mt-20px lg:mt-0 text-right-margin p3 ga-paragraph-content"
                      )}
                    >
                      {index + 1}.{n.value}
                    </p>
                  </>
                )
              )}
          </p>
        </div>
        {infographicTitle1 && (
          <div className="col-span-12 col-start-2 ">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s16 ga-font-weight-700 justify-center"
              )}
            >
              {infographicTitle1}
            </h3>
          </div>
        )}
        {Infographic1 && (
          <div className="col-span-12 col-start-2 ga-infographic-div">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              {infographicTitle1}
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-2 w-full">
              {Infographic1.map(
                (n: {
                  value:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        | string
                        | React.JSXElementConstructor</* eslint-disable @typescript-eslint/no-unsafe-call */ any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <div className="col-span-1 ga-infographic-border">
                    {n.value}
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {btnName && (
          <div className="col-span-12 col-start-2">
            <br></br>
            <center>
              <button
                href={btnLink}
                onClick={() => redirectlinks(btnLink)}
                className={classNames(
                  "col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center",
                  btnclassName
                )}
              >
                <a>
                  <p className="self-center">{btnName}</p>
                </a>
              </button>
            </center>
          </div>
        )}
        {noncategeoryContent && (
          <div className="col-span-12 col-start-2 ga-padding-15 ">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center mb-40px"
              )}
            >
              {noncategeoryContent}
              <sub>{noncategeoryContent1}</sub>
              {noncategeoryContent2}
            </h3>
          </div>
        )}
        {CarbonInfographImage && (
          <div className="col-span-12 col-start-2 ga-infographic-div ">
            <div className="grid grid-cols-1 md:grid-cols-1 w-full">
              <div id={"div" + id}>
                <img
                  src={CarbonInfographImage}
                  id={id}
                  className="ga-image-infogrph w-full  ga-inforgraphic-social-1"
                />
              </div>
            </div>
          </div>
        )}
        {Infographic2 && (
          <div className="col-span-12 col-start-2 ga-infographic1-div">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              {infographicTitle2}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-4 w-full mt-50px">
              {Infographic2.map(
                (n: {
                  title:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        | string
                        | React.JSXElementConstructor</* eslint-disable @typescript-eslint/no-unsafe-call */ any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                  value:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        | string
                        | React.JSXElementConstructor</* eslint-disable @typescript-eslint/no-unsafe-call */ any>
                      >
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => (
                  <div className="col-span-1 ga-infographic1-border">
                    <div className="col-span-12 bg-purple text-white-1 ga-infogrph-head">
                      {n.title}
                    </div>
                    <div className="col-span-12 ga-padding-10 ga-background-lightgrey h-full">
                      {n.value}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {learnmore && (
          <div className="col-span-12 text-center col-start-2">
            <h3
              className={classNames(
                leftClassName,
                "text-dark-blue ga-font-style-s24 ga-font-weight-700 justify-center"
              )}
            >
              <a className="ga-paragraph-title" href="#">
                Learn more
              </a>{" "}
              about our client work in Energy Transition
            </h3>
          </div>
        )}
      </section>
    );
  }
};
